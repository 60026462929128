import React from 'react';
import './Help.css';

function Help() {
  return (
    <div>
      <h1>Help Page Under Construction</h1>
    </div>
  );
}

export default Help;
