import React from 'react';
import './Parts.css';

function Parts() {
  return (
    <div>
      <h1>Parts Page Under Construction</h1>
    </div>
  );
}

export default Parts;
