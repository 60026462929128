import React from 'react';
import './Services.css';

function Services() {
  return (
    <div>
      <h1>Services Page Under Construction</h1>
    </div>
  );
}

export default Services;
