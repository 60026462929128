import React from 'react';
import './FAQ.css';

function FAQ() {
  return (
    <div>
      <h1>FAQ's Page Under Construction</h1>
    </div>
  );
}

export default FAQ;
