import React from 'react';
import './Sales.css';

function Sales() {
  return (
    <div>
      <h1>Sales Page Under Construction</h1>
    </div>
  );
}

export default Sales;
