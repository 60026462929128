import React from 'react';
import './Rentals.css';

function Rentals() {
  return (
    <div>
      <h1>Rentals Page Under Construction</h1>
    </div>
  );
}

export default Rentals;
