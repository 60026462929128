import React from 'react';
import './Navigation.css';

function Navigation() {
  return (
    <div>
      <h1>Navigation Page Under Construction</h1>
    </div>
  );
}

export default Navigation;
